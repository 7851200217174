import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/meta";
import Archive from "../components/archive";

export const Head = () => {
  return (
    <Meta title="กฎหมาย" />
  )
}

const LawPage = ({ data }: any) => {
  return (
    <Archive posts={data.latest.nodes} type="law" />
  );
}

export const pageQuery = graphql`
  query {
    latest: allStrapiLaw(sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...LawData
      }
    }
  }
`

export default LawPage